<template>
  <div id="businessView" class="d-flex flex-column" style="height: 100%;" v-if="business.id !== ''">
    <v-row class="flex-column" no-gutters justify="center">
      <h2 class="display-2 font-weight-bold mb-3 text-center">{{ business.businessName }}</h2>
    </v-row>
    <ContactForm />
  </div>
</template>

<script>
import { Business } from "@/models";
import { Storage } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import _ from "lodash";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "BusinessView",

  components: { ContactForm },

  data: () => ({

    business: {
      id: "",
      image: "",
    },

    loading: true,
    businessSubscription: null,
  }),

  async mounted() {
    await this.queryBusiness();
  },

  methods: {
    async queryBusiness() {
      try {
        const businessId = this.$route.params.domain;
        this.businessSubscription = DataStore.observeQuery(Business, (vc) =>
          vc.domain("eq", businessId)
        ).subscribe(async (snapshot) => {
          const { items, isSynced } = snapshot;
          if (isSynced && items?.length > 0) {
            this.business = _.cloneDeep(items[0]);

            if (this.business.logoKey) {
              this.business.image = await Storage.get(this.business.logoKey,
                {
                  level: "public",
                }
              );
            }

            console.log('this.business:', this.business)
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
  },

  computed: {
    // loaded() {
    //   if ((this.virtualCard.image === "")) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },

  beforeDestroy() {
    if (this.businessSubscription) {
      this.businessSubscription.unsubscribe();
    }
  },
};
</script>
