<template>
  <v-row
    id="top-toolbar"
    no-gutters
    class="pa-1 mb-3"
    :style="
      'border-radius: 4px; color: #ffffff; background-color: ' +
      (profile ? profile.primaryColor : '#208b26') +
      ';'
    "
  >
    <span
      class="px-1 text-h5"
      :style="xs < 600 ? 'padding-top: 1px; width: 100%;' : 'padding-top: 1px;'"
      >{{ toolbarState.title }}</span
    >
    <div
      v-if="xs > 600 && toolbarState.actions.length > 0"
      style="
        height: 30px;
        width: 2px;
        background-color: #ffffff;
        margin-left: 4px;
        margin-top: auto;
        margin-bottom: auto;
      "
    ></div>
    <div v-if="toolbarState.actions.length > 0">
      <v-tooltip
        bottom
        v-for="(action, index) in toolbarState.actions"
        :key="index"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="white"
            v-bind="attrs"
            @click="actionButtonClick(index)"
            v-on="on"
            icon
            ><v-icon>{{ action.icon }}</v-icon></v-btn
          >
        </template>
        <span>{{ action.toolTip }}</span>
      </v-tooltip>
    </div>
    <v-spacer v-if="toolbarState.rightButton"></v-spacer>
    <v-btn
      v-if="toolbarState.rightButton"
      color="white"
      :style="'color:' + (profile ? profile.primaryColor : '#208b26')"
      @click="rightButtonClick"
      >{{ toolbarState.rightButton.text }}</v-btn
    >
  </v-row>
</template>

<script>
import store from "@/store";
export default {
  name: "TopToolbar",
  data: () => ({
    // loading: true,
  }),

  created() {},
  methods: {
    rightButtonClick() {
      this.$emit("rightButtonClick");
    },
    actionButtonClick(action) {
      this.$emit("actionButtonClick", action);
    },
  },
  computed: {
    toolbarState() {
      return store.state.toolbarState;
    },
    profile() {
      return store.state.profile;
    },
    xs() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },
  },
};
</script>