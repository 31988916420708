<template>
  <div class="cards">
    <v-container fluid>
      <TopToolbar @actionButtonClick="actionButtonClick" />
      <v-row v-if="!loading" no-gutters style="margin-left: -4px; margin-right: -4px">
        <div v-for="(vC, i) in virtualCards" :key="i" :style="'width:' +
          (screenWidth < 600
            ? '100%'
            : screenWidth < 800
              ? '50%'
              : screenWidth < 1200
                ? 'calc(100% / 3)'
                : screenWidth < 1500
                  ? '25%'
                  : screenWidth < 1920
                    ? '20%'
                    : '350px')
          ">
          <v-card flat class="mx-1 mb-2" rounded="xl" elevation="10">
            <v-img v-if="vC.image" :src="vC.image"></v-img>
            <div v-if="vC.image" :style="divStyle(vC.themeColors.primaryColor)"></div>
            <v-card-title class="text-h5" style="margin-bottom: -5px">{{ vC.title }} {{ vC.name }} {{ vC.surname
            }}</v-card-title>
            <v-card-text class="pb-0">
              <div v-if="vC.company" style="margin: 5px 0">
                <v-avatar :color="vC.themeColors.primaryColor" size="28">
                  <v-icon dense dark>mdi-home-account</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">{{ vC.company }}</span>
              </div>
              <div v-if="vC.role" style="margin: 5px 0">
                <v-avatar :color="vC.themeColors.primaryColor" size="28">
                  <v-icon dense dark>mdi-account</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">{{ vC.role }}</span>
              </div>
              <div v-if="vC.contact.cell" style="margin: 5px 0">
                <v-avatar :color="vC.themeColors.primaryColor" size="28">
                  <v-icon dense dark>mdi-phone</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">
                  <a :href="hrefCell(vC.contact.cell)" :style="'color:' + vC.themeColors.primaryColor">
                    {{ vC.contact.cell }}
                  </a>
                </span>
              </div>
              <div v-if="vC.contact.email" style="margin: 5px 0">
                <v-avatar :color="vC.themeColors.primaryColor" size="28">
                  <v-icon dense dark>mdi-email</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">
                  <a :href="hrefEmail(vC.contact.email)" :style="'color:' + vC.themeColors.primaryColor">
                    {{ vC.contact.email }}
                  </a>
                </span>
              </div>
            </v-card-text>
            <v-card-actions class="pt-0" style="justify-content: right">
              <v-btn depressed icon min-width="0" color="red" dark @click="deleteVirtualCard(vC)">
                <v-icon>mdi-delete</v-icon>
              </v-btn></v-card-actions>
          </v-card>
        </div>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="addVirtualCardDialog" :style="screenWidth < 600
      ? 'width: 100%; margin: 0; overflow-x: hidden'
      : 'width: 90%; overflow-x: hidden'
      ">
      <v-card>
        <AddVirtualCard @closeVirtualCardDialog="closeVirtualCardDialog" v-if="addVirtualCardDialog" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VirtualCard } from "@/models";
import { Storage } from "@aws-amplify/storage";
import { DataStore } from "@aws-amplify/datastore";
import store from "@/store";
import _ from "lodash";
import TopToolbar from "@/components/TopToolbar";
import AddVirtualCard from "@/components/AddVirtualCard";

export default {
  name: "MyVirtualCardsView",

  components: {
    AddVirtualCard,
    TopToolbar,
  },

  data: () => ({
    addVirtualCardDialog: false,
    virtualCards: [],
    loading: true,
  }),
  mounted() {
    this.queryVirtualCards();
    store.commit("updateToolbarState", {
      title: "Virtual Cards",
      actions: [
        {
          icon: "mdi-plus",
          toolTip: "Create a Virtual Card",
        },
      ],
      rightButton: null,
    });
  },
  methods: {
    async deleteVirtualCard(vC) {
      await DataStore.delete(vC);
      await Storage.remove(vC.imageKey);
    },
    actionButtonClick(action) {
      if (action === 0) {
        this.addVirtualCardDialog = true;
      } else {
        console.log("else:", action);
      }
    },
    closeVirtualCardDialog() {
      this.addVirtualCardDialog = false;
    },
    divStyle(color) {
      return "width: 100%; height: 5px; background-color:" + color;
    },
    hrefCell(cell) {
      return "tel:" + cell;
    },
    hrefEmail(email) {
      return "mailto:" + email;
    },
    async queryVirtualCards() {
      try {
        this.subscription = DataStore.observeQuery(VirtualCard
          // , (vc) =>
          //   vc.owner("eq", store.state.userId)
        ).subscribe(async (snapshot) => {
          this.loading = true;
          const { items, isSynced } = snapshot;
          if (isSynced && items.length > 0 && !this.addVirtualCardDialog) {
            const sortedItems = items.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              if (dateA < dateB) {
                return -1;
              }
              if (dateA > dateB) {
                return 1;
              }
              return 0;
            });
            this.virtualCards = _.cloneDeep(sortedItems);

            for (let i in this.virtualCards) {
              if (this.virtualCards[i].imageKey) {
                this.virtualCards[i].image = await Storage.get(
                  this.virtualCards[i].imageKey,
                  {
                    level: "public",
                  }
                );
              }
            }
            store.commit("hasVirtualCard");

            this.loading = false;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    profile() {
      return store.state.profile;
    },
  },
  beforeDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  },
};
</script>