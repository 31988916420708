<template>
  <div id="dashboardLayout">
    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="font-weight-black headline">
        Bizpoke
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- <v-btn v-if="userSignedIn" text height="100%" to="/cards">
        <v-icon>mdi-cards</v-icon>My businesses
      </v-btn> -->
      <v-btn v-if="userSignedIn && this.$route.path !== '/cards' && (screenWidth > 600)" text height="100%" to="/cards">
        <v-icon>mdi-cards</v-icon>My cards
      </v-btn>
      <v-btn v-if="userSignedIn && this.$route.path !== '/businesses' && (screenWidth > 600)" text height="100%"
        to="/businesses">
        <v-icon style="margin-right: 2px">mdi-home-city</v-icon>My businesses
      </v-btn>
      <v-btn text height="100%" @click="signOut_login_Function()">
        <v-icon>{{
          userSignedIn ? "mdi-logout-variant" : "mdi-login-variant"
        }}</v-icon>
        {{ userSignedIn ? "Sign Out" : "Login" }}
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list nav>
        <v-list-item-group v-model="group">
          <v-list-item to="/" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="h5">Home</v-list-item-title>
          </v-list-item>
          <v-list-item to="/cards" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-cards</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="h5">My Virtual Cards</v-list-item-title>
          </v-list-item>
          <v-list-item to="/businesses" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-home-city</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="h5">My Businesses</v-list-item-title>
          </v-list-item>
          <v-list-item to="/profile" @click="drawer = false">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="h5">My Profile</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main fluid fill-height>
      <router-view />
    </v-main>

    <voeter :showLegal="false" />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import store from "@/store";
import {
  Auth,
} from "aws-amplify";
import { mapState } from "vuex";

export default {
  name: "DashboardLayout",
  data: () => ({
    drawer: false,
    group: null,
  }),
  components: {
    voeter: Footer, //footer is reserved, using something else
  },

  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    ...mapState({
      hasVirtualCards: (state) => state.hasVirtualCard,
      profile: (state) => state.profile,
      userSignedIn: (state) => state.userSignedIn
    }),
  },

  mounted() {
    this.$vuetify.theme.light = true;
    // this.queryUserData();
  },

  methods: {
    async signOut_login_Function() {
      try {
        if (this.userSignedIn) {

          await Auth.signOut();

          store.commit("removeProfile");
          store.commit("storeUser", null);
          if (store.state.userSignedIn) store.commit("storeUserSignedIn", false)

          this.$router.push("/");
        } else {
          this.$router.push({ path: "/profile" }).catch(() => {
            // console.log(err);
          });
        }
      } catch (error) {
        console.log("Error signing out: ", error);
      }
    },
    async queryUserData() {
      try {
        // DataStore.stop();
        // DataStore.configure({
        //   syncExpressions: [
        //     syncExpression(VirtualCard, (vc) =>
        //       vc.userId("eq", store.state.userId)
        //     ),
        //   ],
        // });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>