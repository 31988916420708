import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';

import '@aws-amplify/ui-vue';
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';
import { Amplify, AuthModeStrategyType } from 'aws-amplify';
import awsconfig from './aws-exports';

import vuetify from './plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import '@/sass/main.scss';
import router from './router';
import store from "./store";
import { VueReCaptcha } from 'vue-recaptcha-v3';

Amplify.configure({
  ...awsconfig,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
});

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(VueReCaptcha, {
  siteKey: '6LdS7zQpAAAAAG3JE4MbEBS-2qke5DO88MdluF9x',
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
});

Vue.config.ignoredElements = [/amplify-\w*/];

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
