import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#208b26',
                //secondary: colors.red.lighten4, // #FFCDD2
                //accent: colors.indigo.base, // #3F51B5
            },
            dark: {
                primary: '#208b26',
                //secondary: colors.red.lighten4, // #FFCDD2
                //accent: colors.indigo.base, // #3F51B5
            },
        },
    },
});
