<template>
  <!-- <v-main fluid fill-height> -->
  <div>
    <section id="hero">
      <v-row no-gutters>
        <v-img :min-height="'calc(97vh - ' + $vuetify.application.top + 'px)'"
          :max-height="'calc(97vh - ' + $vuetify.application.top + 'px)'"
          src="https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=99">
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row align="center" class="white--text mx-auto" justify="center">
                <!-- <v-btn depressed to="/addfirstcard" color="primary" dark center>
                  Create your first card
                </v-btn> -->
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span :class="[
                    $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
                  ]" class="font-weight-light">
                    WELCOME TO
                  </span>

                  <br />

                  <div :class="[
                    $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
                  ]" class="font-weight-black d-inline-flex">
                    BIZPOKE
                  </div>

                  <div class="caption d-flex justify-center">
                    a Proud Product of go!App
                  </div>

                  <br />

                  <span :class="[
                    $vuetify.breakpoint.smAndDown ? 'display-5' : 'display-6',
                  ]" class="primary--text">
                    Your Business, Now as an App!
                  </span>
                </v-col>
                <v-btn class="align-self-end" fab outlined @click="$vuetify.goTo('#about-me')">
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <section id="about-me">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-7">
          Transform Your Startup with a <br />Customised Business App
        </h2>

        <!-- <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive> -->

        <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
          In today's digital era, an app isn't just a luxury; it's a necessity.
          Bizpoke, a pioneering product from go!App, brings the power of mobile
          apps to your business. Tailor-made for start-ups, Bizpoke turns your
          business vision into an interactive, user-friendly app that sets you
          apart.
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img :src="require('@/assets/go-app_1920x1920.png')"></v-img>
        </v-avatar>

        <div></div>

        <v-btn color="grey" href="https://go-app.co.za" outlined large>
          <span class="grey--text text--darken-1 font-weight-bold">
            Sign Up Today!
          </span>
        </v-btn>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="features" class="grey lighten-3">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">
          Why Choose Bizpoke for <br />Your Business App?
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <!-- <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider> -->
        </v-responsive>

        <v-row>
          <v-col v-for="({ icon, title, text }, i) in features" :key="i" cols="12" md="3">
            <v-card class="py-12 px-4" color="grey lighten-5" flat height="100%">
              <v-theme-provider dark>
                <div>
                  <v-avatar class="elevation-12 mb-5" color="primary" size="88">
                    <v-icon large>{{ icon }}</v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title class="justify-center font-weight-black text-uppercase text-wrap">{{ title }}</v-card-title>

              <v-card-text class="subtitle-1 text-wrap">
                {{ text }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="about-me">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-7">
          Bizpoke by go!App: <br />Your Business, Now in Everyone’s Pocket!
        </h2>

        <!-- <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive> -->

        <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
          At Bizpoke, we’re not just building apps; we’re empowering dreams.
          Join us and step into the future where your business thrives, not only
          on screens but in the hands of your customers. Embrace the app
          revolution with Bizpoke.
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img :src="require('@/assets/go-app_1920x1920.png')"></v-img>
        </v-avatar>

        <div></div>

        <v-btn color="grey" href="https://go-app.co.za" outlined large>
          <span class="grey--text text--darken-1 font-weight-bold">
            Sign Up Today!
          </span>
        </v-btn>
      </v-container>

      <div class="py-12"></div>
    </section>

    <!-- <section id="stats">
      <v-parallax
        :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        src="https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
      >
        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="3"
            >
              <div class="text-center">
                <div
                  class="display-3 font-weight-black mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>

    <section id="blog">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Blog
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ src, text, title }, i) in articles"
            :key="i"
            cols="12"
            md="4"
          >
            <v-img
              :src="src"
              class="mb-4"
              height="275"
              max-width="100%"
            ></v-img>

            <h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>

            <div class="title font-weight-light mb-5" v-text="text"></div>

            <v-btn class="ml-n4 font-weight-black" text>
              Continue Reading
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section> -->

    <ContactForm />
  </div>
  <!-- </v-main> -->
</template>
    
<script>
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "WebsiteView",

  components: { ContactForm },

  data() {
    return {
      articles: [
        {
          src: "https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
          title: "Mobile first & Responsive",
          text: "Phasellus lorem enim, luctus ut velit eget, convallis egestas eros. Sed ornare ligula eget tortor tempor, quis porta tellus dictum.",
        },
        {
          src: "https://images.unsplash.com/photo-1475938476802-32a7e851dad1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
          title: "Think outside the box",
          text: "Nam ut leo ipsum. Maecenas pretium aliquam feugiat. Aenean vel tempor est, vitae tincidunt risus. Sed sodales vestibulum nibh.",
        },
        {
          src: "https://images.unsplash.com/photo-1416339442236-8ceb164046f8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1892&q=80",
          title: "Small changes, big difference",
          text: "Vestibulum in dictum velit, in rhoncus nibh. Maecenas neque libero, interdum a dignissim in, aliquet vitae lectus. Phasellus lorem enim, luctus ut velit eget.",
        },
      ],
      features: [
        {
          icon: "mdi-devices",
          title: "App-Centric Approach",
          text: "At Bizpoke, we believe every business deserves its own app. Our primary focus is to convert your business model into a fully functional mobile app that resonates with your brand.",
        },
        {
          icon: "mdi-package-variant-plus",
          title: "Customisable to Your Needs",
          text: "With our diverse range of templates and design options, your app will be as unique as your business.",
        },
        {
          icon: "mdi-source-merge",
          title: "Seamless Integration",
          text: "Your app will integrate flawlessly with your website and digital presence, all thanks to the robust technology of go!App.",
        },
        {
          icon: "mdi-handshake-outline",
          title: "Reach Your Audience",
          text: "With a dedicated business app, engage with your customers on the go and offer them a personalised experience.",
        },
      ],
      stats: [
        ["248", "Customers"],
        ["390+", "Products"],
        ["1m", "Downloads/mo"],
        ["5m", "Total Downloads"],
      ]
    };
  },

  methods: {
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
  /* maybe !important  */
}

.heading-container h1 {
  border-bottom: 1px solid black;
  /* Underline for the heading */
  line-height: 1.2em;
  /* Adjust line height to ensure proper spacing */
  padding-bottom: 5px;
  /* Space between text and underline */
}

.subheading {
  float: right;
  /* Aligns the subheading to the right */
  line-height: 0;
  /* Adjust if necessary to align with the bottom of the main heading */
}
</style>