<template>
  <v-container fluid fill-height class="cards" v-if="!loading">
    <v-row class="flex-column" no-gutters justify="center" align="center">
      <card :virtualCard="virtualCard"></card>
      <v-btn class="mt-4" dark :color="virtualCard.theme.primaryColor" center>Add to contacts</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import { VirtualCard } from "@/models";
import { Storage } from "@aws-amplify/storage";
import card from "@/components/VirtualCard";
import { DataStore } from "@aws-amplify/datastore";
import _ from "lodash";

export default {
  name: "VirtualCardView",
  components: {
    card: card,
  },

  data: () => ({
    layout: DashboardLayout,

    virtualCard: {
      image: "",
    },
    loading: true,
    virtualCardSubscription: null,
  }),

  beforeMount() {
    this.queryVirtualCard();
  },
  methods: {
    async queryVirtualCard() {
      try {
        const virtualCardId = this.$route.params.virtualCardId;
        this.virtualCardSubscription = DataStore.observeQuery(VirtualCard, (vc) =>
          vc.id("eq", virtualCardId)
        ).subscribe(async (snapshot) => {
          const { items, isSynced } = snapshot;
          if (isSynced && items?.length > 0) {
            this.virtualCard = _.cloneDeep(items[0]);

            this.virtualCard.image = await Storage.get(
              this.virtualCard.imageKey,
              {
                level: "public",
              }
            );
            // this.subscription.unsubscribe();

            this.loading = false;
          } else if (isSynced && items?.length === 0) {
            this.loading = false;
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  computed: {
    // loaded() {
    //   if ((this.virtualCard.image === "")) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  beforeDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  },
};
</script>
