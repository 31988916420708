<template>
  <v-app>
    <component :is="layoutComponent">
      <router-view />
    </component>
    <!-- <v-btn depressed class="mr-1" @click="createEmailTemplate()" color="black" dark center>Email Stuff</v-btn> -->
  </v-app>
</template>

<script>
import { DataStore } from "@aws-amplify/datastore";
import { mapState } from "vuex";
import { Profile } from "@/models";
import { Auth } from "aws-amplify";
import store from "@/store";
import Vuetify from "@/plugins/vuetify";

export default {
  name: "App",

  data: () => ({
    userDataSubscription: null,
  }),

  computed: {
    layoutComponent() {
      return this.$route && this.$route.meta && this.$route.meta.layout;
    },

    ...mapState({
      userSignedIn: (state) => state.userSignedIn
    }),
  },

  watch: {
    userSignedIn: {
      handler: async function (val) {
        if (val && !this.userDataSubscription) {
          this.queryUserData();
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    try {
      document.addEventListener("loggedIn", this.queryUserData(), { once: true });

      this.user = await Auth.currentAuthenticatedUser().catch((err) => {
        console.log("Error getting user: ", err);
      });

      if (this.user) {
        this.queryUserData();

        store.commit("storeUser", this.user.attributes.sub);
      }

      store.commit("storeUserSignedIn", this.user ? true : false);
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    // createEmailTemplate() {
    //   const AWS = require("aws-sdk");
    //   AWS.config.update({
    //     region: "eu-west-2",
    //     accessKeyId: "AKIAYYWQJAG2CU6QPBWO",
    //     secretAccessKey: "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",
    //   });
    //   var ses = new AWS.SES();
    //   var params = {
    //     Template: {
    //       TemplateName: "ContactUsTemplate",
    //       SubjectPart: "New Bizpoke Contact Form Submitted",
    //       TextPart: `Name: {{inputName}}
    // Email: {{inputEmail}}
    // Subject: {{inputSubject}}
    // Message: {{inputMessage}}`,
    //       HtmlPart:
    //         "<p>Name: {{inputName}}</p><p>Email: {{inputEmail}}</p><p>Subject: {{inputSubject}}</p><p>Message: {{inputMessage}}</p>",
    //     },
    //   };
    //   ses.createTemplate(params, function (err, data) {
    //     if (err) console.log(err, err.stack);
    //     else console.log(data);
    //   });
    // },

    queryUserData() {
      try {
        this.userDataSubscription = DataStore.observeQuery(Profile).subscribe(
          async (snapshot) => {
            const { items, isSynced } = snapshot;
            if (isSynced && items?.length > 0) {
              store.commit("storeProfile", items[0]);

              if (items[0].primaryColor) {
                Vuetify.framework.theme.themes.light.primary =
                  items[0].primaryColor;
                Vuetify.framework.theme.themes.dark.primary =
                  items[0].primaryColor;
              }
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },

  beforeDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  },
};
</script>
