<template>
  <div v-if="!loading" style="margin: 0 8px">
    <v-row no-gutters class="pt-4">
      <v-col cols="12" class="text-center">
        <span v-if="!businessProp" class="text-h3">Add a Business</span>
        <span v-else class="text-h3">Edit Business</span>
        <div :v-if="businessFields.logoUpload">
          <v-img :src="businessFields.logoUpload" max-height="50"></v-img>
        </div>
      </v-col>
    </v-row>
    <v-form v-model="valid">
      <v-stepper flat v-model="step" vertical class="pa-0">
        <v-stepper-step :complete="step > 1" step="1">
          Business Details
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card flat class="mb-2">
            <v-col class="pa-0">
              <v-text-field v-model="businessFields.businessName" :rules="requiredRule"
                label="Business Name"></v-text-field>
              <v-text-field v-model="businessFields.businessSlogan" label="Business Slogan"></v-text-field>
              <v-text-field v-model="businessFields.domain" label="Domain"></v-text-field>
              <v-select v-model="businessFields.layout" label="Layout" :items="layoutTemplates" item-text="label"
                item-value="value" required :rules="requiredRule"></v-select>
              <v-text-field v-model="businessFields.contact.email" label="Business email"></v-text-field>
              <div class="d-flex phoneinputdiv">
                <vue-tel-input-vuetify :value="businessFields.contact.phone" :preferred-countries="['za']"
                  :valid-characters-only="true" @input="onPhoneInput"></vue-tel-input-vuetify>
                <v-select v-if="selectPhoneTitleNotInput" v-model="selectedPhoneTitle" :items="phoneTitleList"
                  @change="whatPhoneTitle(selectedPhoneTitle)" :label="selectedPhoneTitle
                    ? selectedPhoneTitle
                    : 'Select Phone Title'
                    " solo dark flat color="white" background-color="grey darken-1"></v-select>
                <v-text-field v-else-if="!selectPhoneTitleNotInput" v-model="selectedPhoneTitle" label="New Phone Title"
                  autofocus></v-text-field>
              </div>
            </v-col>
          </v-card>
          <v-btn color="primary" @click="step = 2" :disabled="!valid">
            Continue
          </v-btn>
        </v-stepper-content>
        <v-stepper-step :complete="step > 2" step="2">
          Add your Company
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-card flat class="mb-2">
            <v-col class="pa-0">
              <v-file-input v-model="businessFields.logoUpload" prepend-icon="mdi-image-plus" accept="image/*"
                label="Upload Logo"></v-file-input>
              <v-color-picker v-model="businessFields.themeColors.primaryColor" mode="hexa"
                :rules="requiredRule"></v-color-picker>
            </v-col>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </v-form>
    <v-row no-gutters class="pb-2">
      <v-btn color="primary" @click="submit()" :disabled="!valid || saving" :dark="!saving" :saving="saving">
        submit
      </v-btn>
      <v-btn text @click="step = 1" :disabled="step !== 2" :dark="step !== 2">
        Back
      </v-btn>

      <v-btn class="ml-auto" color="primary" @click="cancel()"> Cancel </v-btn>
    </v-row>
  </div>
</template>

<script>
import { DataStore } from "@aws-amplify/datastore";
import { Business } from "@/models";
import { Storage } from "@aws-amplify/storage";
import store from "@/store";
import _ from "lodash";

export default {
  name: "AddVirtualCard",
  components: {},
  props: {
    businessProp: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selectPhoneTitleNotInput: true,
    isSelecting: false,
    step: 1,
    businessFields: {
      businessName: "",
      businessSlogan: "",
      // businessDescription: "",
      // businessAddress: {
      //   street: "",
      //   city: "",
      //   state: "",
      //   zip: "",
      // },
      contact: {
        phones: [],
        email: "",
      },
      themeColors: {
        primaryColor: "",
        secondaryColor: "",
        // tertiaryColor: "#000000",
      },
      properties: {},
      logoUpload: null,
      logoKey: "",
      domain: "",
      layout: "",
    },
    // imagePreview: null,
    phoneTitleList: [
      "Mobile",
      "Work",
      "Home",
      "Fax",
      "Work Mobile",
      "Assistant",
      "MMS",
      "Other",
    ],
    modelBusiness: null,
    selectedPhoneTitle: "Select Phone Title",
    phoneSelectorWidth: "130px",
    logoFile: null,
    saving: false,
    loading: false,
    valid: false,
    errorMessage: "",
    requiredRule: [(v) => !!v || "Required"],
    emailRule: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],
    multipleEmailRule: [
      (v) =>
        !v ||
        /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(,)*)+$/.test(v) ||
        "E-mail must be valid",
    ],
    layoutTemplates: [
      { label: "Blog Layout 1", value: "blog/BlogLayoutTemplate1" },
    ],
  }),
  async mounted() {
    try {
      this.loading = true;
      if (this.businessProp) {
        this.businessFields = _.cloneDeep(this.businessProp);

        this.modelBusiness = this.businessProp;

        this.logoFile = await Storage.get(this.businessProp.logokey, {
          level: "public",
        });
        console.log(this.businessFields);
      } else if (store.state.profile.primaryColor) {
        this.businessFields.themeColors.primaryColor =
          store.state.profile.primaryColor;
      }

      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    whatPhoneTitle(phoneTitle) {
      try {
        this.phoneSelectorWidth = "10px";
        if (phoneTitle === "Other") {
          this.selectPhoneTitleNotInput = false;
          this.selectedPhoneTitle = "";
        } else {
          this.selectedPhoneTitle = phoneTitle;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // async previewImage(imageToUpload) {
    //   if (!imageToUpload) return;
    //   const readData = (f) =>
    //     new Promise((resolve) => {
    //       const reader = new FileReader();
    //       reader.onload = () => resolve(reader.result);
    //       reader.readAsDataURL(f);
    //     });
    //   const data = await readData(imageToUpload);
    //   this.imagePreview = data;
    // },
    onPhoneInput(formattedNumber, { number, valid, country }) {
      this.businessFields.contact.phones.push({
        phoneTitle: this.selectedPhoneTitle,
        number,
      });
      // this.businessFields.contact.phone.internationalNumber =
      //   number.international;
      this.businessFields.contact.phone.valid = valid;
      this.businessFields.contact.phone.country = country && country.name;
      // console.log(formattedNumber);
    },
    async submit() {
      console.log(store.state.profile);
      try {
        this.saving = true;
        if (!this.modelBusiness) {
          const newBusiness = await DataStore.save(
            new Business({
              businessName: this.businessFields.businessName,
              businessSlogan: this.businessFields.businessSlogan,
              domain: this.businessFields.domain,
              layout: this.businessFields.layout,
              properties: this.businessFields.properties,
              // userId: store.state.userId,
              profileID: store.state.profile.id,
              contact: {
                phone: this.businessFields.contact.phone,
                email: this.businessFields.contact.email,
              },
              themeColors: {
                primaryColor: this.businessFields.themeColors.primaryColor,
              },
            })
          );

          if (this.businessFields.logoUpload !== null) {
            const imageURL = await Storage.put(
              store.state.userId +
              "/" +
              newBusiness.id +
              "/" +
              "businessLogo" +
              "/" +
              this.businessFields.logoUpload.name,
              this.businessFields.logoUpload
            );

            await DataStore.save(
              Business.copyOf(newBusiness, (updateBusiness) => {
                // updateVirtualCard.ownerField
                updateBusiness.logoKey = imageURL.key;
              })
            );
          }
        } else {
          let imageURL = null;

          if (this.businessFields.logoUpload) {
            imageURL = await Storage.put(
              store.state.userId +
              "/" +
              this.modelBusiness.id +
              "/" +
              "businessLogo" +
              "/" +
              this.businessFields.logoUpload.name,
              this.businessFields.logoUpload
            );
          }

          await DataStore.save(
            Business.copyOf(this.modelBusiness, (updateBusiness) => {
              updateBusiness.businessName = this.businessFields.businessName;
              updateBusiness.businessSlogan =
                this.businessFields.businessSlogan;
              updateBusiness.domain = this.businessFields.domain;
              updateBusiness.layout = this.businessFields.layout;
              updateBusiness.properties = this.businessFields.properties;
              updateBusiness.logoKey =
                imageURL === null ? this.businessFields.logoKey : imageURL.key;
              // updateBusiness.userId = store.state.userId;
              updateBusiness.profileID = store.state.profile.id;
              updateBusiness.contact = {
                phone: this.businessFields.contact.phone,
                email: this.businessFields.contact.email,
              };
              updateBusiness.themeColors = {
                primaryColor: this.businessFields.themeColors.primaryColor,
              };
            })
          );
        }

        this.saving = false;
        this.$emit("closeBusinessDialog");
      } catch (error) {
        console.log(error);
      }
    },

    async cancel() {
      try {
        this.$emit("closeBusinessDialog");
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    }
  },
  // beforeDestroy() {
  //   if (this.imageKey) {
  //     console.log("this.image = null");
  //   }
  // },
};
</script>
<style >
.phoneinputdiv>.v-input>div>div {
  width: fit-content;
}

.phoneinputdiv>.v-input.v-select>div>div>div>div>input {
  width: v-bind(phoneSelectorWidth);
}

.v-dialog--active {
  overflow-x: hidden !important;
}
</style>