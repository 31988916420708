<template>
  <div id="businessLayout">

    <v-main fluid fill-height>
      <router-view />
    </v-main>

    <voeter :showLegal="false" />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
export default {
  name: "BusinessLayout",
  data: () => ({
  }),

  mounted() {
    this.$vuetify.theme.light = true;
    // this.queryUserData();
  },
  components: {
    voeter: Footer, //footer is reserved, using something else
  },
  methods: {
  },

  // computed: {
  //   screenWidth() {
  //     return this.$vuetify.breakpoint.width;
  //   },
  // },
};
</script>