<template>
  <v-container fluid style="display: flex; height: 90vh; justify-content: center; align-items: center;">
    <amplify-auth style="display: flex; width: min-content;">
      <v-card max-width="min-content" class="d-flex flex-column" flat>
        <amplify-authenticator>
        </amplify-authenticator>
      </v-card>
    </amplify-auth>
  </v-container>
</template>

<script>
import "@aws-amplify/ui/dist/style.css";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import store from "@/store";

export default {
  name: "VueAuthenticator",

  data() {
    return {
      authState: undefined,
      unsubscribeAuth: undefined,
      formFields: [
        {
          type: "email",
          label: "Custom Email Label",
          placeholder: "Custom email placeholder",
          inputProps: { required: true, autocomplete: "username" },
        },
        {
          type: "password",
          label: "Custom Password Label",
          placeholder: "Custom password placeholder",
          inputProps: { required: true, autocomplete: "new-password" },
        },
      ],
    };
  },

  mounted() {
    this.unsubscribeAuth = onAuthUIStateChange((authState
      // , authData
    ) => {
      this.authState = authState;
      // this.$store.commit("auth/setUser", authData);
      //this.user = authData;
      // console.log(authData);
    });
  },

  methods: {},

  watch: {
    authState: {
      handler() {
        // console.log("watch>authState", this.authState);
        if (this.authState === AuthState.SignedIn) {

          if (!store.state.userSignedIn) store.commit("storeUserSignedIn", true)

          if (this.$route.query.redirect) {
            this.$router.push({ path: this.$route.query.redirect });
          } else {
            this.$router.push({ path: '/' });
          }
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.unsubscribeAuth();
  },
};
</script>

<style>
/* Customization of the AWS Amplify colors */
:root {
  --amplify-primary-color: #44a579;
  --amplify-primary-tint: #63b892;
  --amplify-primary-shade: #317959;
}

.password-requirements {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
}

amplify-authenticator {
  margin-bottom: -20px;
  --container-display: contents !important;
}

amplify-section .section {
  border-radius: none !important;
}
</style>