import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store";

import WebsiteLayout from "@/layouts/WebsiteLayout.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";
import BusinessLayout from "@/layouts/BusinessLayout.vue";

import WebsiteLanding from '@/views/website/LandingView.vue';

import Authenticator from '@/components/core/Authenticator.vue';

import AddFirstCardView from '@/views/AddFirstCardView.vue';
import MyVirtualCardsView from '@/views/admin/MyVirtualCardsView.vue';
import VirtualCardView from '@/views/website/VirtualCardView.vue';

import MyBusinessesView from '@/views/admin/MyBusinessesView.vue';
import BusinessView from '@/views/admin/BusinessView.vue';

import MyProfileView from '@/views/admin/MyProfileView.vue';

import { Auth } from 'aws-amplify';

Vue.use(VueRouter);

const hostName = window.location.hostname;
console.log(window.location.host, window.location.hostname);

const bizpokeHosts = ['dev.bizpoke.co.za', 'bizpoke-dev.go-app.co.za', 'dev.d8tz76yyqwa6w.amplifyapp.com', 'master.d8tz76yyqwa6w.amplifyapp.com', 'www.bizpoke.co.za', 'bizpoke.go-app.co.za', 'localhost'];

const routes = [
  {
    path: '/',
    name: 'WebsiteLanding',
    component: () => {
      if (bizpokeHosts.includes(hostName)) {
        return import('@/views/website/LandingView.vue');
      } else {
        return import('@/views/website/BusinessView.vue');
      }
    },
    meta: { layout: WebsiteLayout }
  },
  {
    path: '/auth',
    name: 'Authenticator',
    component: Authenticator,
    meta: { layout: WebsiteLayout }
  },
  {
    path: '/addfirstcard',
    name: 'AddFirstCardView',
    component: AddFirstCardView,
    meta: { requiresAuth: true, layout: DashboardLayout }
  },
  {
    path: '/cards',
    name: 'MyVirtualCardsView',
    component: MyVirtualCardsView,
    meta: { requiresAuth: true, layout: DashboardLayout },
  },
  {
    path: '/profile',
    name: 'MyProfileView',
    component: MyProfileView,
    meta: { requiresAuth: true, layout: DashboardLayout },
  },
  // {
  //   path: '/addfirstbusiness',
  //   name: 'AddFirstBusinessView',
  //   component: AddFirstCardView,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/businesses',
    name: 'MyBusinessesView',
    component: MyBusinessesView,
    meta: { requiresAuth: true, layout: DashboardLayout },
  },
  {
    path: '/b/:domain',
    name: 'BusinessView',
    component: BusinessView,
    meta: { layout: BusinessLayout }
  },
  {
    path: '/c/:virtualCardId',
    name: 'VirtualCard',
    component: VirtualCardView,
    meta: { layout: WebsiteLayout }
  },
  {
    path: "*",
    name: 'NotFound',
    component: WebsiteLanding,
    meta: { layout: WebsiteLayout }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (!requiresAuth) {
    next();
    return;

  } else {

    try {
      const user = await Auth.currentAuthenticatedUser().catch((error) => {
        // Log any errors
        console.log('error:', error)
      });

      if (user) {
        if (!store.state.userSignedIn) store.commit("storeUserSignedIn", true)
      } else {
        if (store.state.userSignedIn) store.commit("storeUserSignedIn", false)
      }

      if (requiresAuth && !user) {
        // console.log(1)
        next({
          path: '/auth',
          query: { redirect: to.fullPath }
        });
      } else if (requiresAuth && user) {
        // console.log(2, to)
        if (!to.meta.includesUsers) {
          // console.log(2.1)
          next();
        } else if (to.meta.includesUsers && to.meta.includesUsers.includes(user.username)) {
          console.log(2.2)
          next();
        } else {
          console.log(2.3)
        }
      }

    } catch (error) {
      // console.log("error", error)
      // User is not authenticated, redirect to the login page
      next({
        path: '/auth',
        query: { redirect: to.fullPath }
      });
    }
  }

});

export default router