<template>
  <div id="websiteLayout">
    <v-app-bar app color="white" height="75">
      <!-- <v-avatar class="mr-3" color="grey lighten-5">
        <v-img contain :src="require('@/assets/go-app_1920x1920.png')"></v-img>
      </v-avatar> -->

      <v-toolbar-title class="font-weight-black headline">
        Bizpoke
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn v-if="userSignedIn" text height="100%" to="/cards">
        <v-icon>mdi-cards</v-icon>My cards
      </v-btn>
      <v-btn v-if="userSignedIn" text height="100%" to="/businesses">
        <v-icon style="margin-right: 2px">mdi-home-city</v-icon>My businesses
      </v-btn>
      <v-btn v-if="userSignedIn && !hasProfile" text height="100%" to="/profile">
        <v-icon>mdi-account</v-icon>Setup profile
      </v-btn>
      <v-btn v-if="$route.path !== '/auth'" text height="100%" @click="signOut_login_Function()">
        <v-icon>{{
          userSignedIn ? "mdi-logout-variant" : "mdi-login-variant"
        }}</v-icon>
        {{ userSignedIn ? "Sign Out" : "Login" }}
      </v-btn>
    </v-app-bar>

    <v-main fluid fill-height>
      <router-view />
    </v-main>

    <voeter :showLegal="false" />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import store from "@/store";
import { Auth } from "aws-amplify";
import { mapState } from "vuex";

export default {
  name: "WebsiteLayout",

  data: () => ({
    // drawer: false,
  }),

  components: {
    voeter: Footer, //footer is reserved, using something else
  },

  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    ...mapState({
      hasProfile: (state) => state.hasProfile,
      userSignedIn: (state) => state.userSignedIn
    }),
  },

  mounted() {
    this.$vuetify.theme.light = true;
  },

  methods: {
    async signOut_login_Function() {
      try {
        if (this.userSignedIn) {
          await Auth.signOut();

          store.commit("removeProfile");
          store.commit("storeUser", null);
          if (store.state.userSignedIn) store.commit("storeUserSignedIn", false)

          if (this.$route.path !== "/") {
            this.$router.push("/");
          }
        } else {
          this.$router.push({ path: "/profile" }).catch(() => {
            // console.log(err);
          });
        }
      } catch (error) {
        console.log("Error signing out: ", error);
      }
    },
  },
  watch: {},
};
</script>
