<template>
  <div id="contactForm">
    <v-sheet id="contact" color="#333333" dark tag="section" tile>
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-center">Contact Us</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <!-- <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider> -->
        </v-responsive>

        <!-- The actual contact form -->
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <!-- Provide a light theme inside the form -->
          <v-theme-provider light>
            <v-row no-gutters>
              <!-- Input field for name -->
              <v-col cols="12">
                <v-text-field v-model="formfields.name" flat label="Name*" solo :rules="nameRules"
                  required></v-text-field>
              </v-col>

              <!-- Input field for email -->
              <v-col cols="12">
                <v-text-field v-model="formfields.email" flat label="Email*" solo :rules="emailRules"
                  required></v-text-field>
              </v-col>

              <!-- Input field for subject -->
              <v-col cols="12">
                <v-text-field v-model="formfields.subject" flat label="Subject*" solo :rules="subjectRules"
                  required></v-text-field>
              </v-col>

              <!-- Textarea for the message -->
              <v-col cols="12">
                <v-textarea v-model="formfields.message" flat label="Message*" solo :rules="messageRules"
                  required></v-textarea>
              </v-col>
              <v-col cols="12" class="text-caption">
                This site is protected by reCAPTCHA and the Google Privacy
                <a target="_blank" href="https://policies.google.com/privacy">Policy</a>
                and
                <a target="_blank" href="https://policies.google.com/terms">Terms of Service</a>
                apply.
              </v-col>
              <!-- Submit button -->
              <v-col class="mx-auto" cols="auto">
                <v-btn class="mt-7" :disabled="!valid || submitted" type="submit" x-large color="primary"
                  :loading="submitting">
                  {{ submitted ? "Thank You" : "Submit" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-theme-provider>
        </v-form>
      </v-container>

      <div class="py-12"></div>
    </v-sheet>
    <v-snackbar centered :value="submitted" :timeout="5000" color="primary">
      Thank you for contacting us. We will get back to you shortly.
    </v-snackbar>
  </div>
</template>
    
<script>
import { API } from "aws-amplify";

export default {
  name: "ContactForm",
  data() {
    return {
      // Data properties
      valid: true, // Form validation status
      submitting: false, // Is form currently submitting
      submitted: false, // Has form been submitted
      // Form fields
      formfields: {
        name: "",
        email: "",
        subject: "",
        message: "",
        recaptchaToken: "",
      },
      // Validation rules for the name field
      nameRules: [(v) => !!v || "Name is required"],
      // Validation rules for the email field
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      // Validation rules for the subject field
      subjectRules: [(v) => !!v || "Subject is required"],
      // Validation rules for the message field
      messageRules: [(v) => !!v || "Message is required"],
    };
  },

  methods: {
    // Method that handles form submission
    async onSubmit() {
      // Retrieve reCAPTCHA token
      const token = await this.$recaptcha("submit");

      // Assign reCAPTCHA token to formfields
      this.formfields.recaptchaToken = token;

      this.submitting = true;

      // Send the form data to the API for processing
      await API.post("bizpokecontactfunctionapi", "/submit", {
        body: this.formfields,
      })
        .then((response) => {
          // Handle successful response from the API
          this.submitting = false; // Set submitting to false, indicating the form has been submitted
          this.submitted = response.success; // Update submitted status based on API response
          this.resetForm();
        })
        .catch((error) => {
          // Log any errors
          console.error(error);
          this.submitting = false; // Set submitting to false, error.
          this.resetForm();
        });
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
      this.formfields = {
        name: "",
        email: "",
        subject: "",
        message: "",
        recaptchaToken: "",
      };
    },
  },
};
</script>