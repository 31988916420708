import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from "@/plugins/vuetify";

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      toolbarState: {
        title: "",
        actions: [],
        rightButton: null,
      },
      userSignedIn: false,
      userId: null,
      hasProfile: false,
      hasBusiness: false,
      hasVirtualCard: false,
      profile: {
        id: '',
        fullName: '',
        primaryColor: '#208b26'
      },
    }
  },
  getters: {
  },
  mutations: {
    storeUser(state, payload) {
      state.userId = payload
    },
    storeUserSignedIn(state, payload) {
      state.userSignedIn = payload
    },
    hasBusiness(state) {
      state.hasBusiness = true
    },
    hasVirtualCard(state) {
      state.hasVirtualCard = true
    },
    storeProfile(state, payload) {
      state.profile = payload;
      state.hasProfile = true
    },
    removeProfile(state) {
      state.profile = {
        fullName: '',
        primaryColor: '#208b26'
      };
      state.hasProfile = false
      Vuetify.framework.theme.themes.light.primary = '#208b26';
      Vuetify.framework.theme.themes.dark.primary = '#208b26';
    },
    updateToolbarState(state, payload) {
      if (state.toolbarState !== payload) {
        state.toolbarState = {
          title: payload.title,
          actions: payload.actions,
          rightButton: payload.rightButton,
        };
      }
    }
  },
  actions: {
  },
  modules: {
  }
})