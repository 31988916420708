<template>
  <div class="landing">
    <v-col>
      <v-stepper flat v-model="step" vertical class="pa-0">
        <v-stepper-step :complete="step > 1" step="1">
          Your Details
          <!-- <small>Summarize if needed</small> -->
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card flat class="mb-2">
            <v-col sm="12" md="5" lg="3" xl="2" class="pa-0">
              <span class="text-h6">First, enter your details:</span>
              <v-text-field v-model="cardFields.title" label="Title:"></v-text-field>
              <v-text-field height="50px" v-model="cardFields.firstName" label="First Name:"></v-text-field>
              <v-text-field v-model="cardFields.surname" label="Last Name:"></v-text-field>
              <v-text-field v-model="cardFields.contact.email" label="Email:"></v-text-field>
              <vue-tel-input-vuetify :preferred-countries="['za']" :valid-characters-only="true"
                @input="onPhoneInput"></vue-tel-input-vuetify>
            </v-col>
          </v-card>
          <v-btn color="primary" @click="step = 2"> Continue </v-btn>
          <!-- <v-btn color="primary" @click="runDebug()"> RunDebug </v-btn> -->
        </v-stepper-content>

        <v-stepper-step :complete="step > 2" step="2">
          Add your Company
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-card flat class="mb-2">
            <v-col sm="12" md="5" lg="3" xl="2" class="pa-0">
              <span class="text-h6">Next, add your company:</span>
              <v-text-field v-model="cardFields.company" label="Company:"></v-text-field>
              <v-text-field height="50px" v-model="cardFields.role" label="Role in Company:"></v-text-field>
            </v-col>
          </v-card>
          <v-row no-gutters>
            <v-btn color="primary" @click="step = 3"> Continue </v-btn>
            <v-btn text @click="step = 1"> Back </v-btn>
          </v-row>
        </v-stepper-content>

        <v-stepper-step :complete="step > 3" step="3">
          Upload/edit your Photo
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-card flat class="mb-6">
            <v-col sm="12" md="5" lg="3" xl="2">
              <span class="text-h6">Upload a picture of yourself:</span>
              <v-file-input class="pt-6" v-model="cardFields.imageUpload" @change="previewImage(cardFields.imageUpload)"
                @click:clear="clearPreviewImage()" prepend-icon="mdi-image-plus" accept="image/*" label="Image input"
                outlined dense></v-file-input>
              <v-img v-if="imagePreview === null" lazy-src="@/assets/placeholder-image.png"
                src="@/assets/placeholder-image.png" width="50%" aspect-ratio="1" contain eager position="center">
                <!-- <v-btn
                  @click="handleFileImport()"
                  width="100%"
                  height="100%"
                  color="#ffffff00"
                >
                  <v-icon>mdi-account</v-icon>
                  Upload
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  @change="onFileChanged()"
                /> -->
              </v-img>
              <v-img v-else-if="imagePreview !== null" lazy-src="@/assets/placeholder-image.png" :src="imagePreview"
                v-model="imagePreview" width="50%" aspect-ratio="1" contain eager position="center"></v-img>
            </v-col>
          </v-card>
          <v-row no-gutters>
            <v-btn color="primary" @click="step = 4"> Continue </v-btn>
            <v-btn text @click="step = 2"> Back </v-btn>
          </v-row>
        </v-stepper-content>
        <v-stepper-step :complete="step > 4" step="4">
          Style your Card
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card flat class="mb-2">
            <v-col sm="12" md="5" lg="3" xl="2" class="pa-0">
              <span class="text-h6">Now we style your card:</span>

              <v-color-picker v-model="cardFields.themeColor" class="ma-0 pt-6" mode="hexa"></v-color-picker>
              <!-- <span>{{ cardFields.themeColor }}</span> -->
            </v-col>
          </v-card>
          <v-row no-gutters>
            <v-btn color="primary" @click="step = 5"> Continue </v-btn>
            <v-btn text @click="step = 3"> Back </v-btn>
          </v-row>
        </v-stepper-content>

        <v-stepper-step step="5"> Confirm your first Card </v-stepper-step>
        <v-stepper-content step="5">
          <!------------------------------>
          <!-- <v-card flat class="mb-2">
            <v-col sm="12" md="5" lg="3" xl="2" class="pa-0">
              <v-card flat :color="cardFields.themeColor.hexa" width="400px">
                <v-card-title>{{
                  cardFields.firstName + " " + cardFields.surname
                }}</v-card-title>
              </v-card>
            </v-col>
          </v-card> -->
          <!------------------------------>
          <v-row no-gutters>
            <v-card flat width="400px" rounded="xl" elevation="10" class="mb-6">
              <v-img :src="imagePreview" width="100%"></v-img>
              <div :style="divStyle(cardFields.themeColor.hexa)"></div>
              <v-card-title class="text-h4">{{ cardFields.title }} {{ cardFields.firstName }}
                {{ cardFields.surname }}</v-card-title>
              <v-card-text>
                <!-- <v-chip
                  style="height: 28px"
                  :color="cardFields.themeColor.hexa"
                  dark
                >
                  Company
                </v-chip> -->
                <v-avatar :color="cardFields.themeColor.hexa" size="28">
                  <v-icon dense dark>mdi-home-account</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">{{ cardFields.company }}</span>
                <div style="height: 10px"></div>
                <v-avatar :color="cardFields.themeColor.hexa" size="28">
                  <v-icon dense dark>mdi-account</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">{{ cardFields.role }}</span>
                <div style="height: 10px"></div>
                <v-avatar :color="cardFields.themeColor.hexa" size="28">
                  <v-icon dense dark>mdi-phone</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">
                  <a :href="hrefCell(cardFields.contact.phone.number)">
                    {{ cardFields.contact.phone.number }}
                  </a>
                </span>
                <div style="height: 10px"></div>
                <v-avatar :color="cardFields.themeColor.hexa" size="28">
                  <v-icon dense dark>mdi-email</v-icon>
                </v-avatar>
                <span style="margin-left: 10px">
                  <a :href="hrefEmail(cardFields.contact.email)">
                    {{ cardFields.contact.email }}
                  </a>
                </span>
              </v-card-text>
            </v-card>
          </v-row>
          <!------------------------------>
          <v-row no-gutters>
            <v-btn color="primary" @click="submit()"> submit </v-btn>
            <v-btn text @click="step = 4"> Back </v-btn>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </v-col>
  </div>
</template>

<script>
import { DataStore } from "@aws-amplify/datastore";
import { VirtualCard } from "@/models";
import { Storage } from "@aws-amplify/storage";
import store from "@/store";

export default {
  name: "AddFirstCardView",
  components: {},
  data: () => ({
    isSelecting: false,
    step: 1,
    cardFields: {
      title: "",
      firstName: "",
      surname: "",
      contact: {
        phone: {
          number: "",
          valid: false,
          country: undefined,
        },
        email: "",
      },
      company: "",
      role: "",
      imageUpload: null,
      themeColor: "#208b26",
    },
    imageKey: null,
    imagePreview: null,
  }),
  async created() {
    this.cardFields.themeColor = store.state.profile.primaryColor;
  },

  methods: {
    divStyle(color) {
      return "width: 100%; height: 5px; background-color:" + color;
    },
    hrefCell(cell) {
      return "tel:" + cell;
    },
    hrefEmail(email) {
      return "mailto:" + email;
    },
    clearPreviewImage() {
      this.imagePreview = null;
      console.log("clear");
    },
    // handleFileImport() {
    //   this.isSelecting = true;

    //   // After obtaining the focus when closing the FilePicker, return the button state to normal
    //   window.addEventListener(
    //     "focus",
    //     () => {
    //       this.isSelecting = false;
    //     },
    //     { once: true }
    //   );

    //   // Trigger click on the FileInput
    //   this.$refs.uploader.click();
    // },
    // onFileChanged(e) {
    //   this.cardFields.imageUpload = e.target.files[0];

    //   this.previewImage(this.cardFields.imageUpload);
    //   // Do whatever you need with the file, liek reading it with FileReader
    // },
    async previewImage(imageToUpload) {
      if (!imageToUpload) return;
      const readData = (f) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(f);
        });
      const data = await readData(imageToUpload);
      this.imagePreview = data;
      console.log(store.state.userId);
    },
    onPhoneInput(formattedNumber, { number, valid, country }) {
      this.cardFields.contact.phone.number = number.international;
      this.cardFields.contact.phone.valid = valid;
      this.cardFields.contact.phone.country = country && country.name;
      // console.log(formattedNumber);
    },
    async submit() {
      try {
        const newVirtualCard = await DataStore.save(
          new VirtualCard({
            title: this.cardFields.title,
            name: this.cardFields.firstName,
            surname: this.cardFields.surname,
            // userId: store.state.userId,
            contact: {
              cell: this.cardFields.contact.phone.number,
              email: this.cardFields.contact.email,
            },
            company: this.cardFields.company,
            role: this.cardFields.role,
            theme: { primaryColor: this.cardFields.themeColor.hexa },
          })
        );

        if (this.cardFields.imageUpload !== null) {
          const imageURL = await Storage.put(
            newVirtualCard.id +
            "/" +
            "virtualCardPicture" +
            "/" +
            this.cardFields.imageUpload.name,
            this.cardFields.imageUpload
          );

          await DataStore.save(
            VirtualCard.copyOf(newVirtualCard, (updateVirtualCard) => {
              // updateVirtualCard.ownerField
              updateVirtualCard.imageKey = imageURL.key;
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  // beforeDestroy() {
  //   if (this.imageKey) {
  //     console.log("this.image = null");
  //   }
  // },
};
</script>
