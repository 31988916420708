<template>
  <v-card flat width="400px" rounded="xl" elevation="10">
    <v-img :src="virtualCard.image" width="100%"></v-img>
    <div :style="divStyle(virtualCard.theme.primaryColor)"></div>
    <v-card-title class="text-h4"
      >{{ virtualCard.title }} {{ virtualCard.name }}
    </v-card-title>
    <v-card-text>
      <v-avatar :color="virtualCard.theme.primaryColor" size="28">
        <v-icon dense dark>mdi-home-account </v-icon>
      </v-avatar>
      <span style="margin-left: 10px">{{ virtualCard.company }}</span>
      <div style="height: 10px"></div>
      <v-avatar :color="virtualCard.theme.primaryColor" size="28">
        <v-icon dense dark>mdi-account</v-icon>
      </v-avatar>
      <span style="margin-left: 10px">{{ virtualCard.role }}</span>
      <div style="height: 10px"></div>
      <v-avatar :color="virtualCard.theme.primaryColor" size="28">
        <v-icon dense dark>mdi-phone</v-icon>
      </v-avatar>
      <span style="margin-left: 10px">
        <a :href="hrefCell(virtualCard.contact.cell)" class="link-danger">
          {{ virtualCard.contact.cell }}
        </a>
      </span>
      <div style="height: 10px"></div>
      <v-avatar :color="virtualCard.theme.primaryColor" size="28">
        <v-icon dense dark>mdi-email</v-icon>
      </v-avatar>
      <span style="margin-left: 10px">
        <a :href="hrefEmail(virtualCard.contact.email)">
          {{ virtualCard.contact.email }}
        </a>
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "VirtualCard",
  props: {
    virtualCard: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: true,
  }),

  created() {},
  methods: {
    divStyle(color) {
      return "width: 100%; height: 5px; background-color:" + color;
    },
    hrefCell(cell) {
      return "tel:" + cell;
    },
    hrefEmail(email) {
      return "mailto:" + email;
    },
  },

  watch: {},
};
</script>

<style>
/* a {
  color: var(color) !important;
} */
</style>